angular
    .module('MyHippoProducer.Services')
    .factory('EndorsementService', function ($http, $q, $log, $mdDialog, $state, $stateParams, PolicyService,
        spinnerService, toaster, WorkflowAdapter, $filter, HeapService) {
        const { API_HOST: apiHost } = window.appConfig;

        const apiRoutes = {
            reset: () => $http.post(`${apiHost}/v1/producer/policy/${PolicyService.id}/endorsement/reset`),
            start: (formData) => $http.post(`${apiHost}/v1/producer/policy/${PolicyService.id}/endorsement/start`, formData),
            update: (updates) => $http.post(`${apiHost}/v1/producer/policy/${PolicyService.id}/endorsement/update`, updates),
            validate: () => $http.post(`${apiHost}/v1/producer/policy/${PolicyService.id}/endorsement/validate`),
            bind: () => $http.post(`${apiHost}/v1/producer/policy/${PolicyService.id}/endorsement/bind`),
        };

        const resetEndorsement = () => {
            spinnerService.show('globalSpinner');
            return apiRoutes.reset().then(() => {
                return PolicyService.fetch(PolicyService.id, null, true);
            }).then(() => {
                toaster.pop('success', 'Reset Endorsement', 'Successful');
            }).finally(() => spinnerService.show('globalSpinner'));
        };

        const startEndorsement = () => {
            return $q(function (resolve, reject) {
                Promise.resolve().then(() => {
                // If there is an existing transaction, we ask to reset the endorsement before starting a new one
                    if (PolicyService.hasActiveTransaction) {
                        const confirmOverwrite = $mdDialog.confirmModal({
                            locals: {
                                title: 'Overwrite Existing Draft?',
                                primary: 'Yes',
                                secondary: 'No',
                                texts: ['Starting a new endorsement will overwrite the current draft'],
                            },
                        });
                        return $mdDialog.show(confirmOverwrite);
                    }
                }).then(() => {
                    return $mdDialog.show({
                        parent: angular.element(document.body),
                        template: '<start-endorsement-modal></start-endorsement-modal>',
                    });
                }).then((formData) => {
                    if (PolicyService.hasActiveTransaction) {
                        return resetEndorsement().then(() => formData);
                    }
                    return formData;
                }).then((formData) => {
                    return apiRoutes.start(formData);
                }).then(resolve)
                    .catch(response => {
                        const error = _.get(response, 'data', {});
                        if (error && error.code === 'unlicensed_state') {
                            HeapService.trackEvent('agentSync-error-unlicensed-state', {
                                'event': 'start-endorsement',
                                'policy_number': PolicyService.overview.policyNumber,
                            });
                            $state.go('portal.licenseError', _.assign({}, $stateParams, { error: error }));
                        }
                        reject();
                    })
                    .finally(() => spinnerService.hide('globalSpinner'));
            });
        };

        const updateEndorsement = () => {
            PolicyService.errors = WorkflowAdapter.validateUpdates();
            return $q(function (resolve, reject) {
                WorkflowAdapter.evaluateOnSaves();
                const updatesByPage = WorkflowAdapter.separateUpdateByPages();
                if (_.isEmpty(updatesByPage)) {
                    return toaster.pop('error', 'Endorsement Error', 'No updates found');
                }

                spinnerService.show('globalSpinner');
                return apiRoutes.update({ updates: updatesByPage }).then(() => {
                    return PolicyService.fetch(PolicyService.id, true);
                }).then(() => {
                    toaster.pop('success', 'Endorsement Update', 'Successful');
                    return resolve();
                }).catch(response => {
                    const errors = _.get(response, 'data', {});
                    if (_.get(response, 'data.code') === 'unlicensed_state') {
                        const licenseError = _.get(response, 'data');
                        HeapService.trackEvent('agentSync-error-unlicensed-state', {
                            'event': 'update-endorsement',
                            'policy_number': PolicyService.overview.policyNumber,
                        });
                        $state.go('portal.licenseError', _.assign({}, $stateParams, { error: licenseError }));
                    } else if (errors) {
                        PolicyService.errors = errors;
                    }
                    reject();
                }).finally(() => {
                    return spinnerService.hide('globalSpinner');
                });
            });
        };

        const bindEndorsement = function () {
            return $q(function (resolve, reject) {
                Promise.resolve().then(() => {
                    const updatesByPage = WorkflowAdapter.separateUpdateByPages();
                    if (!_.isEmpty(updatesByPage)) {
                        spinnerService.show('globalSpinner');
                        return updateEndorsement(updatesByPage);
                    }
                }).then(() => {
                    spinnerService.show('globalSpinner');
                    return apiRoutes.validate();
                }).then(() => {
                    spinnerService.hide('globalSpinner');
                    return $mdDialog.show({
                        template: `
                        <basic-modal
                            title="'Apply Changes to Policy?'"
                            primary-text="'Apply Changes'"
                            secondary-text="'No'"
                        >
                            <p class="simple-text center">The total annual premium {{changes}}. Are you sure you want to apply the  following changes?</p>
                            <transaction-breakdown simple-breakdown="true"></transaction-breakdown>
                        </basic-modal>
                    `,
                        controller: function($scope) {
                            const { smartHomeDiscountAdjustment, policyFees, optionals, base } = PolicyService.cartInfo;

                            const nextPremium = [
                                { label: 'Base', value: base },
                                { label: 'Fees', value: policyFees },
                                { label: 'Optional', value: optionals },
                                { label: 'Smart Home Discount', value: smartHomeDiscountAdjustment },
                            ].filter((item) => !!item.value).reduce((sum, item) => sum + item.value, 0);

                            const currentPremium = PolicyService.overview.currentPremium;
                            const diff = currentPremium - nextPremium;
                            const currencyDiff = $filter('currency')(Math.abs(diff), '$', 0);
                            $scope.changes = diff === 0 ? `is $${nextPremium}` : diff > 0 ? `decreased by ${currencyDiff}` : `increased by ${currencyDiff}`;
                        }
                    });
                }).then(() => {
                    spinnerService.show('globalSpinner');
                    return apiRoutes.bind().then(() => {
                        spinnerService.hide('globalSpinner');
                        return PolicyService.fetch(PolicyService.id);
                    }).catch(response => {
                        const { errors } = _.get(response, 'data', {});
                        if (errors) { PolicyService.errors = errors; }
                        throw response;
                    });
                }).then(() => {
                    toaster.pop('success', 'Apply Endorsement', 'Successful');
                    resolve();
                }).catch((error) => {
                    spinnerService.hide('globalSpinner');
                    if (_.get(error, 'data.code') === 'unlicensed_state') {
                        const licenseError = _.get(error, 'data');
                        HeapService.trackEvent('agentSync-error-unlicensed-state', {
                            'event': 'bind-endorsement',
                            'policy_number': PolicyService.overview.policyNumber,
                        });
                        $state.go('portal.licenseError', _.assign({}, $stateParams, { error: licenseError }));
                    } else if (error && error.status === 403 && !_.isEmpty(error.data)) {
                        const underwritingModal = $mdDialog.confirmModal({
                            locals: {
                                title: 'System Error',
                                secondary: 'Close',
                                texts: [
                                    'Unable to process the requested changes.',
                                    'Please call 1-650-426-0546 for completing your request',
                                ],
                            },
                        });
                        return reject($mdDialog.show(underwritingModal));
                    }
                    reject();
                });
            });
        };

        return { startEndorsement, bindEndorsement, updateEndorsement, resetEndorsement };
    });
